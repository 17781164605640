
export default {
  data() {
    return {
      isScrolled: false,
    };
  },

  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll() {
      const scrollTop = window.scrollY;
      this.isScrolled = scrollTop > 120;
    },
  },
};
