import { render, staticRenderFns } from "./index.vue?vue&type=template&id=41086029&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeHero: require('/vercel/path0/frontend/components/home/HomeHero.vue').default,HomeFeatures: require('/vercel/path0/frontend/components/home/HomeFeatures.vue').default,HomePartners: require('/vercel/path0/frontend/components/home/HomePartners.vue').default})
